import React from 'react';
import LoaderComponentStyled from './styles/LoaderComponentStyled';

const LoadingComponent = () => {
  return (
    <LoaderComponentStyled>
      <h3>Cargando</h3>
      <svg className="svg" width="70" height="26">
        <circle className="a" cx="9" cy="13" r="6" />
        <circle className="b" cx="32" cy="13" r="6" />
        <circle className="c" cx="55" cy="13" r="6" />
      </svg>
    </LoaderComponentStyled>
  );
};

export default LoadingComponent;
