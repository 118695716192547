import { useContext } from 'react';
import AppContext from '../components/App/AppContext';

const useUser = () => {
  const { user } = useContext(AppContext);

  return user;
};

export default useUser;
