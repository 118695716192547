import { useContext } from 'react';
import AppContext from '../components/App/AppContext';

const useSetUser = () => {
  const { setUser } = useContext(AppContext);

  return (rawUser) => {
    setUser(rawUser);
    if (typeof window !== 'undefined')
      window.localStorage.setItem('tkn', rawUser.token);
  };
};

export default useSetUser;
