import { createContext } from 'react';

const contextInterface = {
  user: null,
  setUser: () => {},
};

const AppContext = createContext(contextInterface);

export default AppContext;
