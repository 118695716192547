/* eslint-disable import/prefer-default-export */
export const theme = {
  colors: {
    disabledColor: '#CAD3DE',
    primaryColor: '#2D1441',
    secondaryColor: '#E92070',
    textColor: '#381451',
    secondaryTextColor: '#6236FF',
    whiteColor: '#FFFFFF',
    greyColor: '#AFA6B7',
    lightGreyColor: ' #f4f4f7',
    buttonPrimary: '#E92070',
    buttonPrimaryDisabled: '#CCC4D2',
    buttonSecondary: '#381451',
    buttonSecondaryDisabled: '#381451',
    accent: '#E92070',
  },
  fonts: {
    primaryFont: 'cerapro',
    secondaryFont: 'SFProText',
  },
};
