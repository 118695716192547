import axios, { CancelToken } from 'axios';
import { navigate } from 'gatsby';

const authRequest = (requests) => {
  const timeout = 30000;
  const source = CancelToken.source();
  const r = requests.map((req) => {
    const axiosConfig = {
      baseURL: `${process.env.GATSBY_API_BASE}${req.url}`,
      method: req.method,
      cancelToken: source.token,
    };
    if (req.data) {
      axiosConfig.data = req.data;
    }
    return axios(axiosConfig);
  });
  const onSuccess = axios.spread((...responses) => {
    return responses.map((resp) => resp.data);
  });
  const onError = (error) => {
    if (error.response) {
      const { data } = error.response;
      const { code } = data;

      if (
        code === 'missing_auth_token' ||
        code === 'expired_code' ||
        code === 'invalid_data'
      ) {
        navigate('/');
      }
    }

    return Promise.reject(error.response || error);
  };
  const onEnd = (response) => {
    return response;
  };
  setTimeout(() => {
    source.cancel();
  }, timeout);
  return axios.all(r).then(onSuccess).catch(onError).then(onEnd);
};
export default authRequest;
