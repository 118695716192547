import styled from 'styled-components';

const Wrapper = styled.div`
  z-index: 9000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: #2d1441;

  & > header {
    padding-top: 32px;
    display: flex;
    justify-content: center;
  }
`;

const ContainerStyled = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  justify-items: center;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

const CircleContainerStyled = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  text-align: center;
  margin: auto;
  background-color: white;
  font-size: 14px;
`;

export { Wrapper, ContainerStyled, CircleContainerStyled };
