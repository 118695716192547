import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  position: relative;
  width: auto;
  height: 100%;
  padding: 0;
  margin: 0;
  max-width: 100%;
  ${(p) =>
    p.fullWidth &&
    css`
      width: 100%;
    `}
  ${(p) =>
    p.autoHeight &&
    css`
      height: auto;
    `}
`;

export const Flex = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${(p) =>
    p.fullWidth &&
    css`
      align-items: stretch;
    `}
  ${(p) =>
    p.column &&
    css`
      flex-direction: column;
    `}
  ${(p) =>
    p.columnReverse &&
    css`
      flex-direction: column-reverse;
    `}
  ${(p) =>
    p.rowReverse &&
    css`
      flex-direction: row-reverse;
    `}

  ${(p) =>
    p.spaceBetween &&
    css`
      justify-content: space-between;
    `};
  ${(p) =>
    p.spaceAround &&
    css`
      justify-content: space-around;
    `};
  ${(p) =>
    p.spaceEvenly &&
    css`
      justify-content: space-evenly;
    `};
  ${(p) =>
    p.flexEnd &&
    css`
      justify-content: flex-end;
    `};
  ${(p) =>
    p.center &&
    css`
      justify-content: center;
    `};
  ${(p) =>
    p.alignTop &&
    css`
      align-items: flex-start;
    `};
  ${(p) =>
    p.noHeight &&
    css`
      height: 0;
    `};
  ${(p) =>
    p.fullHeight &&
    css`
      height: 100%;
    `};
`;
