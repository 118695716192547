/**
 *
 * @param {string} key Retrieves from localstorage as an object
 */
export const retrieveFromStorageAsObject = (key) => {
  /* istanbul ignore next */
  if (typeof window !== 'undefined') {
    return JSON.parse(localStorage.getItem(key));
  }
};

/**
 *
 * @param {string} key The name the object will receive when stored
 * @param {Object} object An object to be stored in the localstorage
 */
export const saveIntoStorageAsString = (key, object) => {
  /* istanbul ignore next */
  if (typeof window === 'undefined') {
    return;
  }

  localStorage.setItem(key, JSON.stringify(object));
};

/**
 *
 * @param {string} key The key to be deleted in the storage
 */
export const removeFromStorage = (key) => {
  /* istanbul ignore next */
  if (typeof window === 'undefined') {
    return;
  }

  localStorage.removeItem(key);
};

/**
 *
 * @param {array} arrayOfKeys A group of keys (strings) which will be deleted from the storage
 */
export const removeBatchFromStorage = (arrayOfKeys) => {
  /* istanbul ignore next */
  if (typeof window === 'undefined') {
    return;
  }

  arrayOfKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
};
