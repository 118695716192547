import styled from 'styled-components';

const LoaderComponentStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  svg {
    circle {
      animation-name: blink;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      border-radius: 50%;
      font-size: 130px;
      color: #7c6d89;
      fill: #7c6d89;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }

  h3 {
    color: #7c6d89;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export default LoaderComponentStyled;
