import Auth from '../../../services/AuthService';
import { StringParam, useQueryParam } from 'use-query-params';
import useSetUser from '../../../hooks/useSetUser';
import { getAccessToken, getTmfToken } from '../helpers/LoginServices';

const useLogin = () => {
  const [par] = useQueryParam('par', StringParam);
  const isClient = typeof window !== 'undefined';
  const setUser = useSetUser();

  return async () => {
    if (isClient && par) {
      // PAR LOGIN
      const response = await Auth.autoProcess(par)
        .then(([user]) => {
          if (user.isValid) {
            setUser(user);
            return { status: 200 };
          }
          throw new Error('invalid user');
        })
        .catch(() => ({ status: 400 }));
      return response;
    }
    // SSO LOGIN
    const { accessToken } = await getAccessToken();

    if (accessToken) {
      try {
        const { data: user, status } = await getTmfToken(accessToken);
        if (status === 200) setUser(user);
        return { status };
      } catch (e) {
        return { status: 500 };
      }
    } else return { status: 404 };
  };
};

export { getAccessToken, getTmfToken };

export default useLogin;
