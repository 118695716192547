import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { ReactComponent as LogoWOM } from '../../assets/images/logo-wom.svg';
import {
  Wrapper,
  ContainerStyled,
  CircleContainerStyled,
} from './styles/LoginLoaderStyled';
import useLogin from './hooks/useLogin';
import LoadingComponent from './LoadingComponent';
import useLogout from './hooks/useLogout';
import useUser from '../../hooks/useUser';
import AppLayout from './AppLayout';

const AppManager = ({ location, children }) => {
  const user = useUser();
  const login = useLogin();
  const logout = useLogout();

  useEffect(() => {
    login().then(({ status }) => {
      if (status === 200) {
        if (location.pathname === '/') navigate('/home', { replace: true });
      } else {
        // status 400: Invalid Par
        // status 401: Access Token expired
        // status 403: Darkside
        // status 404: Without access token on request
        logout();
      }
    });
  }, []);

  if (user) return <AppLayout>{children}</AppLayout>;

  return (
    <Wrapper>
      <header>
        <LogoWOM />
      </header>
      <ContainerStyled>
        <CircleContainerStyled>
          <LoadingComponent />
        </CircleContainerStyled>
      </ContainerStyled>
    </Wrapper>
  );
};

AppManager.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node.isRequired,
};

export default AppManager;
