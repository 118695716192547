import { navigate } from 'gatsby';
import JwtDecode from 'jwt-decode';
import {
  removeBatchFromStorage,
  retrieveFromStorageAsObject,
  saveIntoStorageAsString,
} from '../utils/storage/localStorage';
import authRequest from '../shared/authRequest';

const authLocalStorageKeys = {
  user: 'fibraUser',
  username: 'fibraUserName',
  rut: 'rutNumber',
  rutServiceFail: 'rutServiceFail',
  mail: 'mail',
  key: 'key',
  token: 'tkn',
  refreshToken: 'refreshToken',
};

const isBrowser = () => typeof window !== 'undefined';

const getRutNumber = () =>
  retrieveFromStorageAsObject(authLocalStorageKeys.rut);

const setRutNumber = (rut) =>
  saveIntoStorageAsString(authLocalStorageKeys.rut, rut);

const formatRut = (rut) => rut.replace(/[.]/g, '').split('-').join('');

const validRefreshToken = () => {
  const token = localStorage.getItem('refreshToken');

  if (!token) return null;
  const timeNow = new Date().getTime() / 1000;
  const { exp } = JwtDecode(token);

  return timeNow < exp;
};

const rutValidator = async (rut) => {
  const formattedRut = formatRut(rut);
  setRutNumber(formattedRut);

  return authRequest([
    {
      url: `/fiber/auth/challenger/rut/${formattedRut}`,
      method: 'GET',
    },
  ]);
};

const otpValidator = async (rut, otp, mail, key) => {
  return authRequest([
    {
      url: `/fiber/auth/challenger/rut/${rut}/validate?include=token`,
      method: 'POST',
      data: {
        code: otp,
        mail,
        key,
      },
    },
  ]);
};

const autoProcess = (hash) => {
  return authRequest([
    {
      url: `/fiber/auth/auto-process`,
      method: 'POST',
      data: { hash },
    },
  ]);
};

const updateToken = () => {
  const refreshToken = window.localStorage.getItem('refreshToken');
  return authRequest([
    {
      url: `/fiber/auth/refresh-token`,
      method: 'POST',
      data: { refreshToken },
    },
  ]);
};

const ssoRedirect = () => {
  if (isBrowser())
    window.location.replace(`${process.env.SSO_URL}login/womfibra`);
};

const AuthService = {
  rutValidator,
  otpValidator,
  getRutNumber,
  setRutNumber,
  authLocalStorageKeys,
  autoProcess,
  updateToken,
  validRefreshToken,
  ssoRedirect,
};

export default AuthService;
