import AuthService from '../../../services/AuthService';
import { logoutUser } from '../../../helpers/Auth';

const useLogout = () => {
  return () => {
    logoutUser();
    AuthService.ssoRedirect();
  };
};

export default useLogout;
