import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Container, Flex } from '../../utils/flexContainers.styled';

// Theme
import { theme } from './styles/theme.styled';

const FullViewPortContainer = styled(Container)`
  height: 100vh;
`;

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <FullViewPortContainer fullWidth>
        <Flex column spaceBetween fullHeight>
          <Container fullWidth autoHeight>
            <Flex column>{children}</Flex>
          </Container>
        </Flex>
      </FullViewPortContainer>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
