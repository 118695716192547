import axios, { CancelToken } from 'axios';

const getAccessToken = () => {
  const source = CancelToken.source();

  setTimeout(() => {
    source.cancel();
  }, 30000);

  return axios({
    baseURL: `https://${process.env.LM_BASE_URL}api/loginManager/refresh`,
    cancelToken: source.token,
    headers: {
      'lm-api-key': `${process.env.LM_APIKEY}`,
    },
    method: 'post',
    withCredentials: true,
  })
    .then((response) => ({
      accessToken: response.data.access_token,
    }))
    .catch(() => ({
      unlogged: true,
    }));
};

const getTmfToken = (accessToken) => {
  return axios({
    method: 'post',
    url: `${process.env.GATSBY_API_BASE}/fiber/auth/login-manager/login`,
    data: { accessToken },
  });
};

export { getAccessToken, getTmfToken };
