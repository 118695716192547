import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppContext from './AppContext';
import AppManager from './AppManager';
import './styles/app.css';
import '../../assets/fonts/fonts.css';

const AppProvider = ({ children, location }) => {
  const [user, setUser] = useState(null);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      <AppManager location={location}>{children}</AppManager>
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

export default AppProvider;
