const logoutUser = () => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem('fibraUserName');
    window.localStorage.removeItem('tkn');
    window.localStorage.removeItem('location');
  }
};

// eslint-disable-next-line import/prefer-default-export
export { logoutUser };
